import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { setSessionPassword, getSessionPassword, getQueryPassword } from '../utils/utils';
import { Helmet } from 'react-helmet';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Wrapper from '../components/wrap';
import Header from '../components/header';
import Footer from '../components/footer';
import Projects from '../components/projects';
import PPIcon from "../images/icons/pp.inline.svg";
import RTIcon from "../images/icons/roadtrippers.inline.svg";
import UUIcon from "../images/icons/uula.inline.svg";
import SCIcon from "../images/icons/sc.inline.svg";
import CWIcon from "../images/icons/cw.inline.svg";
import TWIcon from "../images/icons/tw.inline.svg";
import WIcon from "../images/icons/w.inline.svg";
import SIcon from "../images/icons/s.inline.svg";
import AFIcon from "../images/icons/af.inline.svg";
import KLMIcon from "../images/icons/klm.inline.svg";
import AMIcon from "../images/icons/am.inline.svg";
import MIcon from "../images/icons/m.inline.svg";
import AIcon from "../images/icons/audi.inline.svg";
import WSIcon from "../images/icons/ws.inline.svg";

const isBrowser = typeof window !== "undefined"

// markup
const IndexPage = () => {
  if (isBrowser) {  
    if (window.location.search && !getSessionPassword()) {
      setSessionPassword(getQueryPassword(window.location).p);
    }
  }
  
  return (
    <>
    <Header location="home" nav={getSessionPassword()}/>
    <div className="global-wrapper">
      <Helmet htmlAttributes={{lang: 'en'}}>
        <title>Rustem — Product Designer</title>
        <link rel="canonical" href="https://rustem.design" />
        <meta property="og:title" content="Rustem — product designer" />
      </Helmet>
      <Wrapper>
        <Greeting>
          <StaticImage
            layout="fixed"
            className="bio-avatar"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../images/photo6.jpg"
            quality={95}
            alt="Rustem Giniyatullin"
          />
          <h1>Hey there,<br />I'm Rustem{" "}<span role="img" aria-label="wave emoji">👋{" "}</span></h1>
          <p className="bubble">
            I'm a design leader with over 15 years of experience who sometimes jumps into frontend and analytics.
            <br />
            Currently, at <a href="http://flatstack.com" target="_blank" rel="noreferrer" className="external">Flatstack</a>.
          </p>
        </Greeting>
        <AnimationOnScroll animateIn="animate__fadeIn" className="intro" animateOnce="true">
          <p>
            I've designed software products from scratch and expanded existing products, solving real-life user problems in a variety of domains. These domains include energy/ecommerce market research tools, social networks, web3, education, travel, health, b2b/b2c platforms, AI, and more. And such a diverse experience has resulted in deeper understanding and thoughtful design within complex ecosystems.
          </p>
          {getSessionPassword() === "Berlin" ? <p className="projects-inline">
            In the last 15+ years I've worked with innovative startups like 
            <a rel="noreferrer" target="_blank" href="https://premiumparking.com" className="company"><PPIcon /> Premium Parking</a>, <a rel="noreferrer" target="_blank" href="https://roadtrippers.com" className="company"><RTIcon className='icons' /> Roadtrippers</a>, <a rel="noreferrer" target="_blank" href="https://uula.io" className="company"><UUIcon /> UULA</a>, <a rel="noreferrer" target="_blank" href="https://www.veracode.com" className="company"><SCIcon className='icons' /> SourceClear</a>, <a rel="noreferrer" target="_blank" href="https://www.clubworx.com" className="company"><CWIcon /> Clubworx</a>, <a rel="noreferrer" target="_blank" href="https://www.twiagemed.com" className="company"><TWIcon /> Twiage</a> and major companies like <a href="https://www.williams-sonoma.com" rel="noreferrer" target="_blank" className="company"><WSIcon className='icons' /> Williams-Sonoma</a>, <a rel="noreferrer" target="_blank" className="company" href="https://klm.com" title="KLM"><KLMIcon title="KLM" aria-label="KLM" /></a>, <a rel="noreferrer" target="_blank" href="https://www.westwoodenergy.com" className="company"><WIcon className='icons' /> Westwood Global Energy Group</a>, <span className="company"><AIcon className='icons' /> Audi (rus)</span>,  <a href="https://www.amtrak.com/" className="company" rel="noreferrer" target="_blank"><AMIcon />Amtrak</a>, <a href="https://wwws.airfrance.us" className="company" rel="noreferrer" target="_blank"><AFIcon />AirFrance</a>, <a href="https://www.scancafe.com" rel="noreferrer" target="_blank" className="company"><SIcon /> ScanCafe</a>, <MIcon />.
          </p>: ''}
          {getSessionPassword() === "Berlin" ? <p id="projects">Here are some projects I have worked on</p> : ''}
        </AnimationOnScroll>
      </Wrapper>
      {getSessionPassword() === "Berlin" ? <Projects type="compact" /> : ''}
      <Footer />
    </div>
    </>
  )
}

const Greeting = styled.div`
  margin: 10vw 0 5vw;
  font-size: var(--fontSize-5);
  overflow: hidden;
  padding: var(--spacing-8) var(--spacing-8) var(--spacing-6) 0;
  
  .bio-avatar {
    border-radius: 25px;
    vertical-align: middle;
    height: 50px;
    margin-top: -35px;
    margin-left: 10px;
    margin-right: -30px;
    display: inline-block;
    overflow: hidden;
    float: right;
    max-width: 450px;
    max-height: 450px;
  }

  @media (max-width: 480px) {
    font-size: var(--fontSize-3);
    margin-bottom: 1rem;
    margin-top: 5rem;

    .bio-avatar {
      max-height: 36px;
      max-width: 36px;
      margin-left: 5px;
      border-radius: 18px;
    }
  }

  @media (orientation: landscape) and (max-width: 900px) {
    margin: 5vw 0 2rem;
  }

  @media (max-width: 900px) {
    font-size: var(--fontSize-2);

    .bio-avatar {
      max-height: 400px;
      max-width: 400px;
    }
  }

  @media (max-width: 665px) {
    padding: 0;
    margin-bottom: 0;
    overflow: visible;

    h1 {
      margin: 0 0 1rem 0;
      font-size: var(--fontSize-3); 
    }

    .bio-avatar {
      max-height: 250px;
      max-width: 250px;
      margin: 0 0 30px 20px;
      border-radius: 10px;
    }
  }

  @media (max-width: 550px) {
    .bio-avatar {
      max-width: 200px;
      max-height: 200px;
      margin: -10px 10px 0 10px;
    }
  }

  @media (max-width: 480px) {
    .bio-avatar {
      margin: -30px 20px 20px 0;
      max-width: 120px;
      max-height: 120px;
      border-radius: 60px;
      float: left;

      img {
        width: 180px;
        height: 150px;
        margin-left: -40px;
      }
    }
  }

  @media (max-width: 360px) {
    .bio-avatar {
      max-width: 100px;
      max-height: 100px;
      border-radius: 50px;
      margin-right: 10px;

      img {
        width: 160px;
        height: 140px;
      }
    }
  }
`

export default IndexPage
